




import { Component, Vue, Prop } from 'vue-property-decorator';
import { mainFormLst } from '@/modules/budget-request/components/js/budgetFormsLst';
import {makeToast} from "@/modules/budget/bip/bip-types";
import store from "@/services/store";

@Component({ name: 'c-budg-form-lst' })
export default class CBudgetFormsList extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private curFormSelect!: any;

    private curForm: any = null;

    private assessLevelMap = new Map();

    public mounted() {
        this.curForm = this.curFormSelect;
        this.getAccessLevelMap();
        this.$watch('store.state.user.userModules', () => { this.getAccessLevelMap(); });
    }

    private formDict: any[] = [];

    private getFormDict() {
        this.formDict = [];
        let fl = false;
        for (let i = 0; i < mainFormLst.length; i++) {
            const obj = this.setNameLang(mainFormLst[i]);
            if (obj.moduleCode !== undefined) {
                if (this.assessLevelMap.get(obj.moduleCode) && [1, 2, 3].includes(this.assessLevelMap.get(obj.moduleCode))) {
                    if (this.curForm === obj.href) {  fl = true; }
                    this.formDict.push(obj);
                }
            } else {
                if (this.curForm === obj.href) {  fl = true; }
                this.formDict.push(obj);
            }
            obj.id = i;
        }
        if (!fl) {
            if (this.formDict.length) {
                this.curForm = this.formDict[0].href;
            } else {
                this.curForm = '';
            }
            this.chgCurFormSelect();
        }
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private chgCurFormSelect() {
        if (!this.curForm) { return; }
        const url: any = this.curForm;
        this.$router.push(url);
    }

    private get userUiid() {
        if (store.state.user.sub) {
            return store.state.user.sub;
        }
        return null;
    } // get global user variant id


    private getAccessLevelMap() {
        const modules: any[] = store.state.user.userModules;
        this.assessLevelMap = new Map();
        for (const row of modules) {
            this.assessLevelMap.set(row.modules, row.access_level);
        }
        this.getFormDict();
    }
}
