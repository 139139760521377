<template>
    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
        <template #button-content>
            <div class="btn-download">
                <div>
                                    <span class="left">
                                        <i class="icon icon-info"></i>
                                    </span>
                    <i class="icon icon-keyboard"></i>
                </div>
            </div>
        </template>
        <b-dropdown-text>
            <div class="info-button">
                <span @click="infoCostForm" v-if="isArbitraryForm">Режим «Произвольная форма»<i
                    class="icon icon-folder"></i></span>
                <span @click="infoCostForm" v-else>Режим «Формы расчетов»<i class="icon icon-folder"></i></span>
            </div>
            <div class="info-button">
                <span class="font-italic" @click="openVideoOnNewTab">Просмотреть видеоинструкцию<i
                    class="icon icon-video"></i></span>
            </div>
            <div class="info-text">
                <p v-if="isArbitraryForm">Данный режим предназначен для ввода, просмотра и изменении данных по
                    произвольным формам расчета на определенный плановый период и год.</p>
                <p v-else>Данный режим предназначен для ввода, просмотра и изменении данных по формам расчета на
                    определенный плановый период и год. </p>
            </div>
            <div class="info-text">
                <p>Формы расчетов являются неотъемлемой частью бюджетной заявки. Бюджетная заявка представляет собой
                    совокупность документов, составляемых администратором бюджетных программ на очередной плановый
                    период для обоснования объемов расходов, подразделяемых на базовые расходы и расходы на новые
                    инициативы </p>
            </div>
        </b-dropdown-text>
    </b-dropdown>
</template>

<script>
import axios from 'axios';

export default {
    name: 'budget-attach-file',
    props: {
        isArbitraryForm: {
            type: Boolean,
            required: false,
            defaut: false
        },
    },
    data() {
        return {
            isLoad: false
        };
    },
    methods: {
        async infoCostForm() {
            if (this.isLoad) {
                this.makeToast('info', 'Внимание', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
            } else {
                this.isLoad = true;
                await axios({
                    method: 'post',
                    url: '/api-py/get-info-zip/1',
                    responseType: 'blob',
                    data: null
                })
                    .then((response) => response.data)
                    .then((data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        const titleOfFile = 'Инструкция по заполнению Форм расчетов';
                        link.setAttribute('download', titleOfFile + '.zip');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                        // handle success
                    })
                    .catch((error) => {
                        this.makeToast('danger', 'Ошибка запроса infoCostForm()', error.toString());
                    })
                    .finally(() => {
                        this.isLoad = false;
                    });
            }
        },
        openVideoOnNewTab() {
            if (this.isArbitraryForm) {
                window.open('https://youtu.be/r0vhgW3alQE');
            } else {
                window.open('https://youtu.be/r0vhgW3alQE');
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение
    }
};
</script>

<style>
.actions-tab-budget-forms .b-dropdown.info .dropdown-menu {
    transform: translate3d(-287px, 30px, 0px) !important;
    text-transform: none;
}

.actions-tab-budget-forms .b-dropdown .dropdown-menu {
    /* transform: translate3d(-105px, 30px, 0px) !important; */
    text-transform: none;
}
</style>