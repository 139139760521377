export const formLst = [
    { 'name_ru': 'Форма 01-123', href: '/form01-123', code: '01-123' },
    { 'name_ru': 'Форма 02-123', href: '/form02-123', code: '02-123' },
    { 'name_ru': 'Форма 01-134', href: '/form01-134', code: '01-134' },
    { 'name_ru': 'Форма 01-136', href: '/form01-136', code: '01-136' },
    { 'name_ru': 'Форма 01-139', href: '/form01-139', code: '01-139' },
    { 'name_ru': 'Форма 01-141', href: '/form01-141', code: '01-141' },
    { 'name_ru': 'Форма 02-141', href: '/form02-141', code: '02-141' },
    { 'name_ru': 'Форма 03-141', href: '/form03-141', code: '03-141' },
    { 'name_ru': 'Форма 04-141', href: '/form04-141', code: '04-141' },
    { 'name_ru': 'Форма 01-142', href: '/form01-142', code: '01-142' },
    { 'name_ru': 'Форма 02-142', href: '/form02-142', code: '02-142' },
    { 'name_ru': 'Форма 03-142', href: '/form03-142', code: '03-142' },
    { 'name_ru': 'Форма 01-143', href: '/form01-143', code: '01-143' },
    { 'name_ru': 'Форма 01-144', href: '/form01-144', code: '01-144' },
    { 'name_ru': 'Форма 02-144', href: '/form02-144', code: '02-144' },
    { 'name_ru': 'Форма 01-149', href: '/form01-149', code: '01-149' },
    { 'name_ru': 'Форма 02-149', href: '/form02-149', code: '02-149' },
    { 'name_ru': 'Форма 03-149', href: '/form03-149', code: '03-149' },
    { 'name_ru': 'Форма 01-151', href: '/form01-151', code: '01-151' },
    { 'name_ru': 'Форма 02-151', href: '/form02-151', code: '02-151' },
    { 'name_ru': 'Форма 03-151', href: '/form03-151', code: '03-151' },
    { 'name_ru': 'Форма 04-151', href: '/form04-151', code: '04-151' },
    { 'name_ru': 'Форма 01-152', href: '/form01-152', code: '01-152' },
    { 'name_ru': 'Форма 01-153', href: '/form01-153', code: '01-153' },
    { 'name_ru': 'Форма 01-159', href: '/form01-159', code: '01-159' },
    { 'name_ru': 'Форма 02-159', href: '/form02-159', code: '02-159' },
    { 'name_ru': 'Форма 02-159 (Дороги)', href: '/form02-159-1', code: '02-159_1' },
    { 'name_ru': 'Форма 02-159 (339)', href: '/form02-159-2', code: '02-159_2' },
    { 'name_ru': 'Форма 03-159', href: '/form03-159', code: '03-159' },
    { 'name_ru': 'Форма 01-161', href: '/form01-161', code: '01-161' },
    { 'name_ru': 'Форма 01-162', href: '/form01-162', code: '01-162' },
    { 'name_ru': 'Форма 01-169', href: '/form01-169', code: '01-169' },
    // { 'name_ru': 'Форма 01-324', href: '/form01-324', code: '01-324' },
    { 'name_ru': 'Форма 02-324', href: '/form02-324', code: '02-324' },
    { 'name_ru': 'Форма 01-413', href: '/form01-413', code: '01-413' },
    { 'name_ru': 'Форма 01-414', href: '/form01-414', code: '01-414' },
    { 'name_ru': 'Форма 02-414', href: '/form02-414', code: '02-414' },
    { 'name_ru': 'Форма 01-416', href: '/form01-416', code: '01-416' },
];

export const mainFormLst = [
    { 'name_ru': 'Все', href: '/budget-request' },
    { 'name_ru': 'Свод по АБП/ГУ', href: '/budget-sum-calc-prog' },
    { 'name_ru': 'Форма ГУ', href: '/budget-sum-calc' },
    { 'name_ru': 'Формы расчётов', href: '/form01-123', code: '01-123' },
    // { 'name_ru': 'Произвольная форма', href: '/form133', code: '133' },
    { 'name_ru': 'Штатное расписание', href: '/staffing-table' },
    { 'name_ru': 'Бюджетные инвестиционные проекты', href: '/bip/bip-form', moduleCode: "004.002.007" },
    { 'name_ru': 'Бюджетные программы', href: '/budget-program' },
    { 'name_ru': 'Лимиты ГУ/ГККП', href: '/budget-gkkp', moduleCode: '004.002.010', 'name_kk': 'МҚКК/MM шектеулеры', 'name_en': 'SPUK/GA limits' }
];

export const mainReportLst = [
    { 'name_ru': 'Прогноз: Формирование бюджета', href: '/budget-report' },
    { 'name_ru': 'План: Утверждение бюджета', href: '/approv-report' },
    { 'name_ru': 'Корректировка бюджета', href: '/adjust-report' },
    { 'name_ru': 'Уточнение бюджета', href: '/clarify-report' }
];

export const specificProp = [
    { name: '212-Выплаты вознаграждений по займам, полученным из вышестоящего бюджета местными исполнительными органами', spf: '212', link: '/form212' },
    { name: '213-Выплаты вознаграждений по внутренним займам местных исполнительных органов', spf: '213', link: '/form213' },
    { name: '331-Субвенции', spf: '331', link: '/form331' },
    { name: '338-Возврат целевых трансфертов', spf: '338', link: '/form338' },
    { name: '352-Возврат части средств, привлеченных из Национального фонда Республики Казахстан', spf: '352', link: '/form352' },
    { name: '511-Бюджетные кредиты местным исполнительным органам, за исключением бюджетных кредитов на реализацию бюджетных инвестиционных проектов', spf: '511', link: '/form511' },
    { name: '513-Бюджетные кредиты специализированным организациям', spf: '513', link: '/form513' },
    { name: '514-Бюджетные кредиты физическим лицам', spf: '514', link: '/form514' },
    { name: '411-Приобретение земли', spf: '411', link: '/form411' },
    { name: '612-Формирование и увеличение уставных капиталов субъектов квазигосударственного сектора', spf: '612', link: '/form612' },
    { name: '711-Погашение основного долга перед вышестоящим бюджетом', spf: '711', link: '/form711' },
    { name: '712-Погашение основного долга по государственным эмиссионным ценным бумагам, размещенным на внутреннем рынке', spf: '712', link: '/form712' },
    { name: '154-Оплата услуг по исследованиям', spf: '154', link: '/form154' },
    { name: '156-Оплата консалтинговых услуг', spf: '156', link: '/form156' },
    { name: '157-Оплата услуг на проведение форумов, семинаров, конференций', spf: '157', link: '/form157' },
    { name: '419-Приобретение прочих основных средств', spf: '419', link: '/form419' },
    { name: '418-Материально-техническое оснащение государственных предприятий', spf: '418', link: '/form418' },
    { name: '322(Персоны)-Трансферты физическим лицам', spf: '322', link: '/form322' },
    { name: '02-322-Трансферты физическим лицам', spf: '02-322', link: '/form02-322' },
    { name: '412-Приобретение помещений, зданий, сооружений, передаточных устройств', spf: '412', link: '/form412' },
    { name: '163-Затраты Фонда всеобщего обязательного среднего образования', spf: '163', link: '/form163' },
    { name: '133-Возмещение средней заработной платы депутатам маслихата по их основному месту работы', spf: '133', link: '/form133' },
    { name: '311-Субсидии физическим и юридическим лицам, в том числе крестьянским (фермерским) хозяйствам', spf: '311', link: '/form311' },
    { name: '155-Оплата услуг в рамках государственного социального заказа', spf: '155', link: '/form155' },
    { name: '158-Расчет расходов по оплате услуг на имиджевые мероприятия', spf: '158', link: '/form158' },
    { name: '813-Выполнение государственных обязательств по компенсации операционных (эксплуатационных) затрат по проектам государственно-частного партнерства', spf: '813', link: '/form813' },
    { name: '423-Капитальный ремонт помещений, зданий, сооружений государственных предприятий', spf: '423', link: '/form423' },
    { name: '812-Выполнение государственных обязательств по компенсации инвестиционных затрат по проектам государственно-частного партнерства', spf: '812', link: '/form812' },
    { name: '814-Выполнение государственных обязательств по выплате вознаграждений за осуществление управления объектов государственно-частного партнерства', spf: '814', link: '/form814' },
    { name: '321-Жилищные выплаты сотрудникам специальных государственных органов, органа по противодействию коррупции, органов внутренних дел и военнослужащим', spf: '321', link: '/form321' },
    { name: '815-Выполнение государственных обязательств по прочим выплатам по проектам государственно-частного партнерства', spf: '815', link: '/form815' },
    { name: '417-Приобретение биологических активов', spf: '417', link: '/form417' },
    { name: '01-339-Текущие трансферты другим уровням государственного управления', spf: '01-339', link: '/form01-339' },
    { name: '02-339-Текущие трансферты другим уровням государственного управления', spf: '02-339', link: '/form02-339' },
    { name: '332-Бюджетные изъятия', spf: '332', link: '/form332' },
    { name: '421-Капитальный ремонт  помещений, зданий, сооружений, передаточных устройств', spf: '421', link: '/form421' }
].sort((a: any, b: any) => { return a.spf - b.spf; });