<template>
        <img :src="fileLink" alt="icon">
</template>

<script>
export default {
    name: 'FileIcons',
    props: {
        filename: null,
    },
    data() {
        return {
            word: ['doc', 'docm', 'docx'],
            excel: ['csv', 'xls', 'xlsm', 'xlsx'],
            powerpoint: ['pps', 'ppsm', 'ppt', 'pptm', '', 'pptx'],
            jpg: ['jpg', 'jpeg', 'jpe'],
            pdf: ['pdf'],
            png: ['png'],
            txt: ['txt', 'text'],
            zip: ['zip', 'rar'],
        }
    },
    computed: {
        fileLink() {
            if (!this.filename) return; 
            let iconName = 'otherfile';
            const extnshn =  this.filename.split('.')[this.filename.split('.').length-1];
            if (this.word.includes(extnshn)) iconName = 'word';    
            if (this.excel.includes(extnshn)) iconName = 'excel';
            if (this.powerpoint.includes(extnshn)) iconName = 'powerpoint';
            if (this.jpg.includes(extnshn)) iconName = 'jpg';
            if (this.pdf.includes(extnshn)) iconName = 'pdf';
            if (this.png.includes(extnshn)) iconName = 'png';
            if (this.txt.includes(extnshn)) iconName = 'txt';
            if (this.zip.includes(extnshn)) iconName = 'zip';
            return `img/svg-icons/${iconName}.svg`;
        }
    }
}
</script>