export default {
    data() {
        return {
            categoryFiles: [
                { value: null, name_ru: 'Выберите категорию файла', disabled: true },
                { value: '1001', name_ru: 'Договоры' },
                { value: '1002', name_ru: 'Коммерческие предложения' },
                { value: '1003', name_ru: 'Акты' },
                { value: '1004', name_ru: 'Сметы' },
                { value: '1005', name_ru: 'Планы' },
                { value: '1006', name_ru: 'Заключения' },
                { value: '1007', name_ru: 'Расчеты' }
            ],
        }
    }
}