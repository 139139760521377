<template>
    <div class="filter-breadcrumb forms-filter-breadcrumb" v-if="header">
            <span v-if="header && header.cur_year" class="item-breadcrumb" @click="openFilterByRef('curYearRef')">
                {{ header.cur_year }}-{{ header.cur_year + 2 }}
            </span>
        <span v-if="header && header.year" class="item-breadcrumb" @click="openFilterByRef('yearRef')">
                {{ header.year }}
            </span>
        <span v-if="dataTypeFilter" class="item-breadcrumb" @click="openFilterByRef('dataTypeRef')">
                {{ dataTypeFilter }}
            </span>
        <span v-if="variantName" class="item-breadcrumb" @click="openFilterByRef('budgetVersionRef')">
                {{ variantName }}
            </span>
        <span v-if="header && header.abp" class="item-breadcrumb" @click="openFilterByRef('abpRef')">
                {{ header.abp }}-АБП
            </span>
        <span v-if="header.mode === 'gu' && header && header.gu"  class="item-breadcrumb" @click="openFilterByRef('guRef')">
                {{ header.gu }}-ГУ
            </span>
        <span v-if="header.mode === 'gkkp' && header && header.gkkp"  class="item-breadcrumb" @click="openFilterByRef('gkkpRef')">
                {{ header.gkkp }}-ГККП
            </span>
        <span v-if="header && header.prg" class="item-breadcrumb" @click="openFilterByRef('prgRef')">
                {{ header.prg }}-БП
            </span>
        <span v-if="header && header.ppr" class="item-breadcrumb" @click="openFilterByRef('pprRef')">
                {{ header.ppr }}-БПП
            </span>
        <span 
            v-if="header && header.spfName"
            class="item-breadcrumb"
            :title="header.spfName.length > 60 ? header.spfName : null"
            @click="openFilterByRef('specRef')">
                {{ header.spfName | length60 }}
            </span>
        <span 
            v-if="header && header.formName"
            class="item-breadcrumb"  
            :title="header.formName.length > 60 ? header.formName : null"
            @click="openFilterByRef('formRef')">
                {{ header.formName | length60 }}
            </span>
        
    </div>
</template>

<script>
export default {
    name: 'breadcrumbs-filter',
    props: {
        header: Object,
        dataTypeFilter: String,
        variantName: String
    },
    methods: {
        openFilterByRef(refName) {
            this.$emit('openFilterByRef', refName);
        }
    },
    filters: {
        length60: function(value) {
            if (!value) return '';
            value = value.toString();
            const newVal = value.length >= 60 ? `${value.substring(0, 60)}...` : value;
            return newVal;
        }
    },
};
</script>

<style scoped>
.forms-filter-breadcrumb {
    display: inline-block;
    height: fit-content;
}
</style>