<template>
    <div>
        <div class="fileupload">
            <div v-if="variantAttribute" class="load-block">
                <span class="file-upload-trigger" @click="openModalFileUpload"><i class="icon icon-analytics"></i> <b>Загрузить файл</b></span>
            </div>
            <b-tabs class="inside-content">
                <b-tab active>
                    <template #title>
                        Договоры (<span>{{(contractFiles && contractFiles.length > 0) ? contractFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in contractFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="downloadFile(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, contractFiles)">
                            <i class="icon icon-close"></i>
                            <span>Удалить</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        Коммерческие предложения (<span>{{(comOfferFiles && comOfferFiles.length > 0) ? comOfferFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in comOfferFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="downloadFile(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, comOfferFiles)">
                            <i class="icon icon-close"></i>
                            <span>Удалить</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        Акты (<span>{{(actFiles && actFiles.length > 0) ? actFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in actFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="downloadFile(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, actFiles)">
                            <i class="icon icon-close"></i>
                            <span>Удалить</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        Сметы (<span>{{(estimateFiles && estimateFiles.length > 0) ? estimateFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in estimateFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="downloadFile(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, estimateFiles)">
                            <i class="icon icon-close"></i>
                            <span>Удалить</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        Планы (<span>{{(planFiles && planFiles.length > 0) ? planFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in planFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="downloadFile(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, planFiles)">
                            <i class="icon icon-close"></i>
                            <span>Удалить</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        Заключения (<span>{{(conclusionFiles && conclusionFiles.length > 0) ? conclusionFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in conclusionFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="downloadFile(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, conclusionFiles)">
                            <i class="icon icon-close"></i>
                            <span>Удалить</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        Расчеты (<span>{{(calculationFiles && calculationFiles.length > 0) ? calculationFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in calculationFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="downloadFile(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, calculationFiles)">
                            <i class="icon icon-close"></i>
                            <span>Удалить</span>
                        </p>
                    </div>
                </b-tab>
                <template #tabs-end>
                    <li role="presentation" class="nav-item align-self-center">
                        <a href="#" class="nav-link download-all" @click="downloadAllFile(false)">Скачать все
                            <span>({{(files && files.length > 0) ? files.length : 0}})</span></a>
                    </li>
                    <li v-if="variantAttribute && showDeleteAll && files.length" role="presentation" class="nav-item align-self-cente">
                        <p class="nav-link delete-all" @click="onDelAllFiles">
                            <i v-if="!deletigFiles" class="icon icon-close"></i>
                            <b-spinner v-if="deletigFiles" small variant="danger" label="Spinning"></b-spinner>
                            Удалить все <span>({{files.length}})</span>
                        </p>
                    </li>
                </template>
            </b-tabs>
        </div>

         <!-- !!! эта модалка используется в форме расчета 149-03 в расшифровках. Учитывать при изменении   -->
        <b-modal ref="modalFileUpload" id="modal-file-upload" modal-class="modal-file-upload" v-model="modalFileUpload" @hidden="closeModal" centered hide-header hide-footer size="sm">
            <b-overlay :show="btnDisabled" rounded="sm">
                <div class="modal-title">Выберите  категорию файла</div>
                <b-form-group>
                    <b-form-select v-model="selectedCategory" :options="categoryFiles" text-field="name_ru"></b-form-select>
                </b-form-group>

                <b-form-file id="file"
                            accept="*.*"
                            v-model="file"
                            @input="uploadFile"
                            multiple
                            placeholder="Загрузить"
                ></b-form-file>
                <p class="upload-btn-label">(Максимальный размер: 50 MB)</p>
            </b-overlay>
        </b-modal>

<!--        &lt;!&ndash; Все, что ниже, не относится к новому дизайну &ndash;&gt;-->
<!--        <hr>-->
<!--        <p style="color: #838383; margin-left: 20px;"><b>Старый дизайн, но с работающим функционалом</b></p>-->

<!--        <div class="table-container fileupload">-->
<!--            <b-table-->
<!--                :fields="fileFields"-->
<!--                :items="files"-->
<!--                responsive="true"-->
<!--                bordered-->
<!--                head-variant="light"-->
<!--                sticky-header="true"-->
<!--                no-border-collapse-->
<!--            >-->
<!--                <template #cell(file_name)="data">-->
<!--                    <b-link target="_blank" href="#" @click="downloadFile(data.item.file_name)">{{ data.value }}</b-link>-->
<!--                </template>-->
<!--                <template #cell(size)="data">-->
<!--                    <div>{{ data.item.size }}</div>-->
<!--                </template>-->
<!--                <template #cell(more)="data">-->
<!--                    <b-button @click="delFile(data.item.id, data.index, data.item.file_name)">-->
<!--                        <i class="icon icon-close"></i>-->
<!--                    </b-button>-->
<!--                </template>-->
<!--                <template #bottom-row="data">-->
<!--                    <td><b-form-file size="sm"-->
<!--                                     id="file" accept="*.*"-->
<!--                                     v-model="file"-->
<!--                                     @input="uploadFile"-->
<!--                                     multiple-->
<!--                    ></b-form-file></td>-->
<!--                    <td></td>-->
<!--                </template>-->
<!--            </b-table>-->
<!--        </div>-->
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Ax } from '@/utils';
import axios from 'axios';
import store from "../../services/store";
import FileUpdownMixin from './mixins/file-updown-mixin';
import FileIcons from './components/file-icons.vue';

export default {
    name: 'FilesUpdown',
    components: { FileIcons },
    props: {
        header: null,
        load: Boolean,
        variantAttribute: Boolean,
        showDeleteLinks: {type: Boolean,
                          default: false},
        showDeleteAll: {type: Boolean,
                          default: true},
    },
    mixins: [FileUpdownMixin],
    data() {
        return {
            fileFields: [
                {
                    key: 'file_name',
                    label: 'Прикрепленные файлы'
                },
                {
                    key: 'size',
                    label: ''
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            files: [],
            // categoryFiles: [
            //     { value: null, name_ru: 'Выберите категорию файла', disabled: true },
            //     { value: '1001', name_ru: 'Договора' },
            //     { value: '1002', name_ru: 'Коммерческие предложения' },
            //     { value: '1003', name_ru: 'Акты' },
            //     { value: '1004', name_ru: 'Сметы' },
            //     { value: '1005', name_ru: 'Планы' },
            //     { value: '1006', name_ru: 'Заключения' },
            //     { value: '1007', name_ru: 'Расчеты' }
            // ],  вынесено в миксин
            selectedCategory: null,
            contractFiles: [],
            comOfferFiles: [],
            actFiles: [],
            estimateFiles: [],
            planFiles: [],
            conclusionFiles: [],
            calculationFiles: [],
            file: null,
            modalFileUpload: false,
            rowId: null,
            deletigFiles: false,
            btnDisabled: false
        };
    },

    created() {
        this.$watch('load', this.loadFiles);
    },

    methods: {
        async delFile(file_type, file_id, index, file_name, array) {
            this.$bvModal.msgBoxConfirm(
                'Удалить выбранный файл?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.deleteFile(file_id, index, file_name, array, file_type);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }, // удаление файла

        async deleteFile(file_id, index, file_name, array, file_type) {
            const that = this;
            try {
                await this.$emit('delelteAttachedFile', file_name, file_type);
                const response = await fetch('/api-py/delete-file/' + file_id + '/' + file_name, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: { id: file_id }
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {

                    array.splice(index, 1);
                    this.$emit('restetFilesList');
                    const newFiles = [];
                    that.files.forEach((file, index) => {
                        if (file.id !== file_id) {
                            newFiles.push(file)
                            this.$emit('fillFilesList', file);
                        }
                    });
                    that.files = newFiles
                    that.getFiles();
                    that.makeToast('success', 'Сообщение', 'Файл удален');
                    this.$emit('delFile', file_id);
                }
            } catch {
                that.makeToast('danger', 'Предупреждение', 'Ошибка удаления файла');
            }
        }, // удаляет файл с хранилища

        onDelAllFiles() {
            this.$bvModal.msgBoxConfirm(
                'Удалить все файлы формы?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        this.deleteAllFiles();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }, // удаление всех файлов

        async deleteAllFiles() {
            this.deletigFiles = true;
            this.files.forEach(file => {
                this.$set(file, 'file_hash', this.stringToUnicode(file.category + file.file_name));
            })
            const data = {files: this.files, header: this.header, showDeleteLinks: this.showDeleteLinks}
            try {
                const response = await fetch('/api-py/delete-all-files/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.files.splice(0);
                    this.contractFiles.splice(0);
                    this.comOfferFiles.splice(0);
                    this.actFiles.splice(0);
                    this.estimateFiles.splice(0);
                    this.planFiles.splice(0);
                    this.conclusionFiles.splice(0);
                    this.calculationFiles.splice(0);
                    this.$emit('delelteAttachedFile');
                    this.$emit('restetFilesList');
                    this.makeToast('success', 'Сообщение', 'Файлы удалены');
                    this.deletigFiles = false;
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления файлов');
                this.deletigFiles = false;
            }
        },

       stringToUnicode(string) {
            let chr = '';
            if (string.length === 0) return hash;
            for (let i = 0; i < string.length; i++) {
                chr += string.charCodeAt(i);
            }
            return chr;
        },

        downloadFile(filename) {
            const that = this;
            Ax(
                {
                    url: '/api-py/download-file/' + filename,
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.makeToast('danger', 'Ошибка скачивания', error.toString());
                }
            );
        }, // скачать файл

        downloadAllFile(filesArr = false) {
            const that = this;
            const allFileNames = [];
            const files = filesArr || that.files;
            files.forEach(file => {
                allFileNames.push(file.file_name);
            });

            const usrId = store.state.user.sub;

            if (allFileNames && allFileNames.length > 0) {
                Ax(
                    {
                        url: '/api-py/download-all-file/' + usrId,
                        method: 'POST',
                        data: allFileNames,
                        responseType: 'blob'
                    },
                    async (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Все файлы.zip');// or any other extension
                        document.body.appendChild(link);
                        link.click();

                        await axios.post('/api-py/delete-zip-file-by-name/' + usrId); // запрос чтобы удалить сгенерированный архив
                    },
                    async (error) => {
                        that.makeToast('danger', 'Ошибка скачивания', error.toString());
                        await axios.post('/api-py/delete-zip-file-by-name/' + usrId); // запрос чтобы удалить сгенерированный архив
                    }
                );
            }
        }, // скачать архив всех прикрепленных файлов

        getFiles() {
            const data = this.files;
            this.$emit('getFiles', data);
        },

        async loadFiles() {
            const that = this;
            if (that.load) {
                this.$emit('restetFilesList');
                that.files.splice(0);
                try {
                    const response = await fetch('/api-py/get-files/' + JSON.stringify(that.header));
                    that.files = await response.json();
                    that.contractFiles = [];
                    that.comOfferFiles = [];
                    that.actFiles = [];
                    that.estimateFiles = [];
                    that.planFiles = [];
                    that.conclusionFiles = [];
                    that.calculationFiles = [];
                    that.files.forEach(f => {
                        this.fillArrsByCategory(f);
                    });
                    that.getFiles();
                } catch (error) {
                    that.makeToast('danger', 'Ошибка запроса loadFiles()', error.toString());
                }
            }
        },

        fillArrsByCategory(item) {
            const that = this;
            const category = item.category;
            switch (category) {
                case '1001':
                    that.contractFiles.push(item);
                    break;
                case '1002':
                    that.comOfferFiles.push(item);
                    break;
                case '1003':
                    that.actFiles.push(item);
                    break;
                case '1004':
                    that.estimateFiles.push(item);
                    break;
                case '1005':
                    that.planFiles.push(item);
                    break;
                case '1006':
                    that.conclusionFiles.push(item);
                    break;
                case '1007':
                    that.calculationFiles.push(item);
                    break;
            }
            this.$emit('fillFilesList', item);
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        openModalFileUpload() {
            this.modalFileUpload = !this.modalFileUpload;
            this.rowId = null;
        },

        setRowId(row_id) {
            this.rowId = row_id;
        },

        closeModal() {
            this.selectedCategory = null;
        },

        async uploadFile() {
            if (this.selectedCategory === null) {
                this.makeToast('danger', 'Предупреждение', 'Вы не выбрали категорию');
                this.file = null;
            } else {
                const newAddedFiles = [];
                const that = this;
                for (let i = 0; i < that.file.length; i++) {
                    const formData = new FormData();
                    formData.append('file', that.file[i], that.file[i].name);
                    this.btnDisabled = true;
                    // that.$set(that.header, 'file_name', that.file[i].name);
                    await axios.post('/api-py/upload-file/' + JSON.stringify(that.header) + '/' + that.selectedCategory,
                        formData,
                        {
                            'Accept': 'application/json',
                            'Content-Type': 'multipart/form-data'
                        }
                    )
                        .then(response => {
                            if (response.data && response.data.existed) {
                                that.makeToast('danger', 'Предупреждение', `Файл с ${that.file[i].name} наименованием уже существует в хранилище. Переименуйте файл для загрузки!`);
                            } else {
                                const resp = response.data;
                                that.fillArrsByCategory({
                                    id: resp.id,
                                    file_name: that.file[i].name,
                                    size: resp.size,
                                    category: resp.category,
                                    createdTime: resp.createdTime,
                                    new_file: true
                                });
                                that.files.push({
                                    id: resp.id,
                                    file_name: that.file[i].name,
                                    size: resp.size,
                                    category: resp.category,
                                    createdTime: resp.createdTime
                                });
                                newAddedFiles.push({
                                    id: null,
                                    file_id: resp.id,
                                    file_name: that.file[i].name,
                                    file_type: resp.category,
                                    row_id: this.rowId,
                                    createdTime: resp.createdTime,
                                    size: resp.size
                                });
                                that.makeToast('success', 'Сообщение', `Файл ${that.file[i].name} успешно загружен`);
                            }
                        })
                        .catch((error) => {
                            that.makeToast('danger', 'Предупреждение', error.toString());
                        })
                        .finally(() => this.btnDisabled = false)
                }
                this.$emit('getNewFiles', newAddedFiles);
                that.getFiles();
                that.file = null;
            }
        }, // скачать файл

        showModalFileUpload() {
            this.$refs.modalFileUpload.show();
        }
    },
    watch: {
        header: function () {
            this.loadFiles();
        }
    }
};
</script>

<style scoped>
.delete-all {
    color: #d34641 !important;
    cursor: pointer;
}
.delete-all i {
    font-size: 0.6rem;
    margin-right: 0.3rem;
}
.upload-btn-label {
    font-size: 0.6rem;
    font-style: italic;
    opacity: 0.6;
}
</style>